import React, { useState, useEffect } from 'react';
import './Register.css';
import { useAuth } from './AuthProvider';
import LoginWithPhone from './Login';

interface RegisterProps {
  onLoginSuccess: () => void;
  onClose: () => void;
}

declare global {
  interface Window {
    google: any;
  }
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const Register: React.FC<RegisterProps> = ({ onLoginSuccess, onClose }) => {
  const { login, register } = useAuth();
  const [isLogin, setIsLogin] = useState(false);
  const [isPhoneLogin, setIsPhoneLogin] = useState(true);
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [registerName, setRegisterName] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [registerCity, setRegisterCity] = useState('');
  const [registerStreet, setRegisterStreet] = useState('');
  const [registerHouseNumber, setRegisterHouseNumber] = useState('');
  const [registerPhone, setRegisterPhone] = useState('');
  const [formErrors, setFormErrors] = useState({
    loginEmail: '',
    loginPassword: '',
    registerName: '',
    registerEmail: '',
    registerPassword: '',
    registerCity: '',
    registerStreet: '',
    registerHouseNumber: '',
    registerPhone: '',
  });
  const [registrationMessage, setRegistrationMessage] = useState('');
  const [loginError, setLoginError] = useState<string>('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: '851165767509-30sql5l6vo3nkg3d025skn2833b9f92b.apps.googleusercontent.com', // Replace with your actual client ID
          callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById('googleSignInButton'),
          { theme: 'outline', size: 'large' } // customization options
        );
      }
    };
    document.body.appendChild(script);
  }, []);

  const handleCredentialResponse = async (response: any) => {
    console.log("Encoded JWT ID token: " + response.credential);
  
    try {
      const res = await fetch(`${API_BASE_URL}/api/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: response.credential }),
      });
  
      if (!res.ok) {
        throw new Error(`Login failed: ${res.statusText}`);
      }
  
      const data = await res.json();
      console.log(data);
  
      if (data.token) {
        localStorage.setItem('authToken', data.token);
        onLoginSuccess();
        onClose();
      } else {
        console.error('Login failed', data.message);
      }
    } catch (error) {
      console.error('Error during login:', error);
      // Handle error, e.g., display error message to user
    }
  };
  

  const toggleForm = () => {
    if (!isLogin && !isPhoneLogin) {
      setIsLogin(true);
      setIsPhoneLogin(false);
    } else {
      setIsLogin(false);
      setIsPhoneLogin(false);
    }
    setLoginEmail('');
    setLoginPassword('');
    setRegisterName('');
    setRegisterEmail('');
    setRegisterPassword('');
    setRegisterCity('');
    setRegisterStreet('');
    setRegisterHouseNumber('');
    setRegisterPhone('');
    setFormErrors({
      loginEmail: '',
      loginPassword: '',
      registerName: '',
      registerEmail: '',
      registerPassword: '',
      registerCity: '',
      registerStreet: '',
      registerHouseNumber: '',
      registerPhone: '',
    });
    setRegistrationMessage('');
    setLoginError('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));

    switch (name) {
      case 'loginEmail':
        setLoginEmail(value);
        break;
      case 'loginPassword':
        setLoginPassword(value);
        break;
      case 'registerName':
        setRegisterName(value);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          registerName: value.length < 2 ? 'Name must have at least 2 characters' : '',
        }));
        break;
      case 'registerEmail':
        setRegisterEmail(value);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          registerEmail: !value.includes('@') ? 'Email must contain @' : '',
        }));
        break;
      case 'registerPassword':
        setRegisterPassword(value);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          registerPassword: value.length < 6 ? 'Password must be at least 6 characters long' : '',
        }));
        break;
      case 'registerCity':
        setRegisterCity(value);
        break;
      case 'registerStreet':
        setRegisterStreet(value);
        break;
      case 'registerHouseNumber':
        setRegisterHouseNumber(value);
        break;
      case 'registerPhone':
        setRegisterPhone(value);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          registerPhone: value.length < 10 ? 'Phone must have at least 10 characters' : '',
        }));
        break;
      default:
        break;
    }
  };

  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let valid = true;

    if (!loginEmail) {
      setFormErrors((prevErrors) => ({ ...prevErrors, loginEmail: 'Email is required' }));
      valid = false;
    }

    if (!loginPassword) {
      setFormErrors((prevErrors) => ({ ...prevErrors, loginPassword: 'Password is required' }));
      valid = false;
    }

    if (!valid) return;

    try {
      await login(loginEmail, loginPassword);
      onLoginSuccess();
      onClose();
    } catch (error) {
      console.error('Error during login:', error);
      setLoginError('Invalid email or password. If you do not have an account, please register.');
    }
  };

  const handleRegisterSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!registerName.trim() || !registerEmail.trim() || !registerPassword.trim() || !registerCity.trim() || !registerStreet.trim() || !registerHouseNumber.trim() || !registerPhone.trim()) {
      setFormErrors({
        ...formErrors,
        registerName: !registerName.trim() ? 'Name is required' : '',
        registerEmail: !registerEmail.trim() ? 'Email is required' : '',
        registerPassword: !registerPassword.trim() ? 'Password is required' : '',
        registerCity: !registerCity.trim() ? 'City is required' : '',
        registerStreet: !registerStreet.trim() ? 'Street is required' : '',
        registerHouseNumber: !registerHouseNumber.trim() ? 'House number is required' : '',
        registerPhone: !registerPhone.trim() ? 'Phone number is required' : '',
      });
      return;
    }

    try {
  
      await register(registerName, registerEmail, registerPassword, registerCity, registerStreet, registerHouseNumber, registerPhone, );
      setRegistrationMessage('Registration successful');
      setRegisterName('');
      setRegisterEmail('');
      setRegisterPassword('');
      setRegisterCity('');
      setRegisterStreet('');
      setRegisterHouseNumber('');
      setRegisterPhone('');
      setTimeout(() => {
        onClose();
      }, 2000);
  
    } catch (error) {
      console.error('Error during registration:', error);
      setRegistrationMessage('Registration failed');
    }
  };

  return (
    <div className="auth-overlay">
      <div className="auth-form-container">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
       
  
        <div className="form-toggle-container">

          <button
            className={`toggle-form-button ${isPhoneLogin ? 'active' : ''}`}
            onClick={() => {
              setIsLogin(false);
              setIsPhoneLogin(!isPhoneLogin); // Toggle isPhoneLogin state
            }}
          >
            <h3>התחבר באמצעות GOOGLE</h3>
          </button>
          <button
            className={`toggle-form-button ${isLogin && !isPhoneLogin ? 'active' : ''}`}
            onClick={() => {
              setIsLogin(true);
              setIsPhoneLogin(false);
            }}
          >
            <h3>התחבר באמצעות מייל וסיסמא</h3>
          </button>
        </div>
        <div className="form-content">
          {isPhoneLogin ? (
            <LoginWithPhone onClose={onClose} onRegister={function (): void {
              throw new Error('Function not implemented.');
            }} />
          ) : isLogin ? (
            <form className='form' onSubmit={handleLoginSubmit}>
              <h2 className="Signinp">כניסה</h2>
              <input
                type="email"
                name="loginEmail"
                value={loginEmail}
                onChange={handleInputChange}
                placeholder="כתובת מייל"
                className="inputs"
              />
              <div className="error-message">{formErrors.loginEmail}</div>
              <input
                type="password"
                name="loginPassword"
                value={loginPassword}
                onChange={handleInputChange}
                placeholder="סיסמה"
                className="inputs"
              />
              <div className="error-message">{formErrors.loginPassword}</div>
              <button type="submit" className="submitB">
                התחבר
              </button>
              {loginError && <div className="error-message">{loginError}</div>}
            </form>
          ) : (
            <form className='form' onSubmit={handleRegisterSubmit}>
              <h2 className="Signupp">הרשמה</h2>
              <input
                type="text"
                name="registerName"
                value={registerName}
                onChange={handleInputChange}
                placeholder="שם מלא"
                className="inputs"
              />
              <div className="error-message">{formErrors.registerName}</div>
              <input
                type="email"
                name="registerEmail"
                value={registerEmail}
                onChange={handleInputChange}
                placeholder="כתובת מייל"
                className="inputs"
              />
              <div className="error-message">{formErrors.registerEmail}</div>
              <input
                type="password"
                name="registerPassword"
                value={registerPassword}
                onChange={handleInputChange}
                placeholder="סיסמה"
                className="inputs"
              />
              <div className="error-message">{formErrors.registerPassword}</div>
              <input
                type="text"
                name="registerCity"
                value={registerCity}
                onChange={handleInputChange}
                placeholder="עיר"
                className="inputs"
              />
              <div className="error-message">{formErrors.registerCity}</div>
              <input
                type="text"
                name="registerStreet"
                value={registerStreet}
                onChange={handleInputChange}
                placeholder="רחוב"
                className="inputs"
              />
              <div className="error-message">{formErrors.registerStreet}</div>
              <input
                type="text"
                name="registerHouseNumber"
                value={registerHouseNumber}
                onChange={handleInputChange}
                placeholder="מספר בית"
                className="inputs"
              />
              <div className="error-message">{formErrors.registerHouseNumber}</div>
              <input
                type="tel"
                name="registerPhone"
                value={registerPhone}
                onChange={handleInputChange}
                placeholder="מספר טלפון"
                className="inputs"
              />
              <div className="error-message">{formErrors.registerPhone}</div>
              <button className="submitB" type="submit">הרשמה</button>

            </form>
            
          )}

          {registrationMessage && <div className="registration-message">{registrationMessage}</div>}
          <div className="toggleLink">
            <button className="toggleB" onClick={toggleForm}>{isLogin || isPhoneLogin ? 'אין לך חשבון? הרשם כאן' : 'יש לך חשבון? לחץ כאן'}</button>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default Register
