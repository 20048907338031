import React, { useState } from 'react';
import './Home.css';
import './VegetableCard.css';
// If you have a modal-specific CSS, create one or add styles to an existing file

interface Product {
  ProductID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  AllowsHalfKg: boolean;
  canToggleUnits: boolean;
  isOutOfStock: boolean;
  Description?: string; // optional
}

interface VegetableCardProps {
  product: Product;
  onAddToCart: (product: Product, isKg: boolean, priceInKg: number) => void;
  onInputChange: (productName: string, newValue: string) => void;
  inputValue: string;
  isSearchResult?: boolean;
}

const VegetableCard: React.FC<VegetableCardProps> = ({
  product,
  onAddToCart,
  onInputChange,
  inputValue,
  isSearchResult,
}) => {
  console.log(`VegetableCard.tsx - ${product.Name}: isOutOfStock = ${product.isOutOfStock}`);

  // States for kg/unit toggling
  const [isKg, setIsKg] = useState(product.Unit === 'kg');
  const [isUnitsButtonActive, setIsUnitsButtonActive] = useState(!isKg);
  const [isKgButtonActive, setIsKgButtonActive] = useState(isKg);

  // State for the popup (modal)
  const [showPopup, setShowPopup] = useState(false);

  const cardClassName = isSearchResult ? 'veGisDivs searchResult' : 'veGisDivs';

  // Toggling logic
  const toggleToKg = () => {
    setIsKg(true);
    setIsKgButtonActive(true);
    setIsUnitsButtonActive(false);
  };

  const toggleToUnits = () => {
    setIsKg(false);
    setIsKgButtonActive(false);
    setIsUnitsButtonActive(true);
  };

  // Price calculation
  const calculatePrice = () => {
    const quantity = parseFloat(inputValue) || 0;
    if (product.Unit === 'bag') {
      const pricePerPiece = product.Price / product.Quantity;
      return Number((pricePerPiece * quantity).toFixed(2));
    } else if (product.Unit === 'item') {
      return Number((product.Price * quantity).toFixed(2));
    } else {
      const pricePerUnit = isKg ? product.Price : product.Price / 6;
      return Number((pricePerUnit * quantity).toFixed(2));
    }
  };

  // Increment/Decrement
  const incrementQuantity = () => {
    const currentVal = parseFloat(inputValue) || 0;
    const increment = product.AllowsHalfKg && isKg ? 0.5 : 1;
    const newValue = (currentVal + increment).toFixed(
      product.AllowsHalfKg && isKg ? 1 : 0
    );
    onInputChange(product.Name, newValue);
  };

  const decrementQuantity = () => {
    const currentVal = parseFloat(inputValue) || 0;
    const decrement = product.AllowsHalfKg && isKg ? 0.5 : 1;
    const newValue = Math.max(0, currentVal - decrement).toFixed(
      product.AllowsHalfKg && isKg ? 1 : 0
    );
    onInputChange(product.Name, newValue);
  };

  const handleAddToCart = () => {
    if (product.isOutOfStock) {
      alert(`${product.Name} is out of stock`);
      return;
    }
    const price = calculatePrice();
    if (product.Unit === 'bag') {
      onAddToCart(product, true, price);
    } else {
      onAddToCart(product, isKg, price);
    }
  };

  // Handle image click -> open popup
  const handleImageClick = () => {
    setShowPopup(true);
  };

  // Close popup
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className={cardClassName}>

      <div className="imgContainer">

        <img
          className={`vegetablesImgs ${product.isOutOfStock ? 'disabled-img' : ''}`}
          src={product.ImgUrl}
          alt={product.Name}
          onClick={handleImageClick} // <-- open popup when clicking the image
          style={{ cursor: 'pointer' }}
          
        />
             <div className="hoverOverlayText">למידע נוסף</div>

        {product.isOutOfStock && (
          <div className="out-of-stock-overlay">
            {/* Optional "Out of Stock" text */}
          </div>
        )}
      </div>


      <div className="h3Divs">
        <h3 className="vegetableName">{product.Name}</h3>
      </div>

      <div className="pDiv">
        {product.Unit === 'bag' ? (
          <p>מארז: ₪ {product.Price.toFixed(2)}</p>
        ) : product.Unit === 'item' ? (
          <p>יחידה: ₪ {product.Price.toFixed(2)}</p>
        ) : (
          <p>ק"ג: ₪ {product.Price.toFixed(2)}</p>
        )}
      </div>

      <div className="buttonsDiv">
        <div className="addToCartDiv">
          <button
            className="addToCartButton"
            onClick={handleAddToCart}
            disabled={product.isOutOfStock}
          >
            {product.isOutOfStock ? 'אזל מהמלאי' : 'הוסף לסל'}
          </button>
        </div>
        <div className="AddRemoveDiv">
          <button
            className="quantity-btn"
            onClick={decrementQuantity}
            disabled={product.isOutOfStock}
          >
            -
          </button>
          <span className="spanNum">{inputValue}</span>
          <button
            className="quantity-btn"
            onClick={incrementQuantity}
            disabled={product.isOutOfStock}
          >
            +
          </button>
        </div>

        {product.canToggleUnits && (
          <div className="toggleButtons">
            <button
              className={isUnitsButtonActive ? 'toggle-button active' : 'toggle-button'}
              onClick={toggleToUnits}
              disabled={product.isOutOfStock}
            >
              יח
            </button>
            <button
              className={isKgButtonActive ? 'toggle-button-second active' : 'toggle-button-second'}
              onClick={toggleToKg}
              disabled={product.isOutOfStock}
            >
              ק"ג
            </button>
          </div>
        )}
      </div>

      {/* RENDER POPUP (MODAL) IF showPopup == true */}
      {showPopup && (
        <div className="modalBackdrop" onClick={closePopup}>
          <div className="modalContent" onClick={(e) => e.stopPropagation()}>
            {/* Prevent clicking on modal from closing it */}
            <button className="closeModalBtn" onClick={closePopup}>
              X
            </button>
            <h2>{product.Name}</h2>
            {/* Use product.Description if available */}
            <p>{product.Description || 'Here is some more info about this product...'}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VegetableCard;
