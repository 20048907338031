import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import './Home.css';
import VegetableCard from './VegetableCard'; // Still named "VegetableCard" file
import { useAuth } from './AuthProvider';
import Register from './Register';
import { useCart } from './CartProvider';
import { availableAreas } from './availableAreas';
import PaymentForm from './PaymentForm';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

// Renamed interface from "Vegetable" to "Product"
interface Product {
  ProductID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  AllowsHalfKg: boolean;
  canToggleUnits: boolean;
  isOutOfStock: boolean;
  CategoryID?: number; // Made optional
}

interface CustomerDetails {
  Name: string;
  Email: string;
  Phone: string;
  Street: string;
  HouseNumber: string;
  City: string;
}

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { currentUser, logout } = useAuth();
  const {
    handleAddToCart,
    inputValues,
    setInputValues,
    cartQuantities, // Now each value is a CartItem with full product details
    handleDeleteCartItem,
    calculateTotalPrice,
    handleIncrementQuantity,
    handleDecrementQuantity,
    // If your CartProvider uses setProducts, rename accordingly:
    setProducts: setProviderProducts
  } = useCart();

  const isAdmin = currentUser?.email === 'admin@example.com';
  const navigate = useNavigate();

  // Local states for search, modals, etc.
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const [products, setProducts] = useState<Product[]>([]);

  const [showRegister, setShowRegister] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [address, setAddress] = useState('');
  const [showAccessibilityMenu, setShowAccessibilityMenu] = useState(false);
  const [showtheCart, setShowtheCart] = useState(false);
  const [showCartPopup, setShowCartPopup] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  // Customer details
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails | null>(null);
  const [editAddress, setEditAddress] = useState(false);
  const [newAddress, setNewAddress] = useState({
    Street: '',
    HouseNumber: '',
    City: '',
  });

  // Fetch products on mount
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/products`);
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();

      // Update the product images if needed
      const updatedData: Product[] = data.map((prod: Product) => ({
        ...prod,
        ImgUrl: `${IMAGE_BASE_URL}/${prod.ImgUrl}`,
      }));

      // Local state update
      setProducts(updatedData);

      // If CartProvider needs the product list, update that as well
      setProviderProducts(updatedData);

      // Initialize input values for all products
      const initialInputValues: { [key: string]: string } = {};
      updatedData.forEach((prod: Product) => {
        initialInputValues[prod.Name] = '0';
      });
      setInputValues(initialInputValues);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // Fetch customer details if logged in
  useEffect(() => {
    if (currentUser && currentUser.id) {
      fetchCustomerDetails(currentUser.id);
    } else {
      setCustomerDetails(null);
    }
  }, [currentUser]);

  const fetchCustomerDetails = async (userID: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/customers/details/${userID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch customer details');
      }
      const data = await response.json();
      setCustomerDetails(data);
    } catch (error) {
      console.error('Error fetching customer details:', error);
    }
  };

  const handleContinueToPay = () => {
    if (!currentUser) {
      setShowRegister(true);
    } else {
      setShowCartPopup(true);
    }
  };

  // Search logic
  const handleCloseSearchResults = () => {
    setSearchResults([]);
    setSearchQuery('');
  };

  const handleSearch = () => {
    if (searchQuery.trim() !== '') {
      const filtered = products.filter((prod) =>
        prod.Name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSearchResults(filtered);
    } else {
      setSearchResults([]);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  // Handle input for "Add to Cart"
  const handleInputChange = (productName: string, newValue: string) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [productName]: newValue,
    }));
  };

  // Add item to cart
  const handleAddToCartClick = (product: Product, isKg: boolean) => {
    handleAddToCart(product, isKg);
  };

  // Check service availability
  const handleCheckService = () => {
    const available = availableAreas.some((area) => address.includes(area));
    setShowPopup(false);
    alert(available ? 'Service is available in your area.' : 'Service not available in your area.');
  };

  const handleLogout = () => {
    logout();
    navigate('/home');
  };

  // Accessibility toggles
  const toggleAccessibilityMenu = () => {
    setShowAccessibilityMenu(!showAccessibilityMenu);
  };

  const adjustTextSize = (size: string) => {
    document.documentElement.style.fontSize = size;
  };

  const toggleHighContrast = () => {
    document.body.classList.toggle('high-contrast');
  };

  // Stub: filter logic by category ID
  function filterProductsByCategory(categoryId: number): Product[] {
    return products.filter((prod) => prod.CategoryID === categoryId);
  }

  // Cart popup toggle functions
  const handleOpenCartPopup = () => {
    setShowtheCart(false);
    setShowCartPopup(true);
  };

  const handleCloseCartPopup = () => {
    setShowCartPopup(false);
  };

  // Address editing
  const handleEditAddressClick = () => {
    setEditAddress(true);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAddress({
      ...newAddress,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveAddress = () => {
    setEditAddress(false);
    setCustomerDetails((prev) =>
      prev
        ? {
            ...prev,
            ...newAddress,
          }
        : null
    );
  };

  // Payment
  const handlePaymentSubmit = (paymentData: any) => {
    console.log('Payment data:', paymentData);
    setShowPaymentForm(false);
    setShowCartPopup(false);
  };

  const handlePaymentClose = () => {
    setShowPaymentForm(false);
  };

  return (
    <header
      className="HeaderDiv"
      role="banner"
      style={{ backgroundImage: `url('${IMAGE_BASE_URL}/GP8.jpg')` }}
    >
      <nav aria-label="Main navigation">
        <div className="BBLinksDiv">
          <button className="homeB" onClick={() => setShowRegister(true)} aria-label="Account">
            <span className="link-text">חשבון</span>
          </button>
<button className="homeB">
          <a
  
  href="https://api.whatsapp.com/send?phone=0542567775"
  target="_blank"
  rel="noopener noreferrer"
  aria-label="Customer Service"
>
  <img
    className="whatsupicon"
    src={`${IMAGE_BASE_URL}/WhatsApp_icon.png`}
    alt="WhatsApp Icon"
  />
  שירות לקוחות
</a>
</button>


          {currentUser && (
            <button className="homeB">
              <Link to="/welcome" aria-label={`Welcome, ${currentUser.name}`}>
                ברוך הבא, {currentUser.name}
              </Link>
            </button>
          )}
          {isAdmin && (
            <button className="homeB">
              <Link to="/VegetableManager" aria-label="Manager">
                מנהל
              </Link>
            </button>
          )}
          {currentUser && (
            <button className="homeB" onClick={handleLogout} aria-label="יציאה">
              יציאה
            </button>
          )}

          <button className="cartButton" onClick={() => setShowtheCart((prev) => !prev)}>
            🛒 עגלת קניות({Object.keys(cartQuantities).length})
          </button>

          {showtheCart && (
            <div className="CartDiv" style={{ backgroundImage: `url('${IMAGE_BASE_URL}/woodenbox2.png')` }}>
              <div className="HeaderCart">
                {Object.keys(cartQuantities).length > 0 && (
                  <>
                    <button className="totalPrice">
                      ₪ סך הכל: {calculateTotalPrice()}
                    </button>
                    <button className="continueToPayBtn" onClick={handleContinueToPay}>
                      המשך לתשלום
                    </button>
                  </>
                )}
              </div>

              {Object.keys(cartQuantities).length === 0 ? (
                <div className="emptyCart">
                  <p className="PPCart">העגלה שלך ריקה כרגע הוסף כמה פריטים</p>
                  <img
                    className="cartimg"
                    src={`${IMAGE_BASE_URL}/עגלה2.webp`}
                    alt="Cart is empty"
                  />
                </div>
              ) : (
                <div className="wrapDiv">
                  {/* Convert cartQuantities object into an array */}
                  {Object.values(cartQuantities).map((cartItem, index) => {
                    const { product, quantity, isKg } = cartItem;
                    if (!product || product.isOutOfStock) return null;

                    let pricePerUnit = 0;
                    if (isKg) {
                      pricePerUnit = product.Price;
                    } else if (product.Unit === 'bag') {
                      pricePerUnit = product.Price / product.Quantity;
                    } else {
                      pricePerUnit = product.Price / 2.26;
                    }

                    return (
                      <div className="CartItems" key={product.ProductID}>
                        <div className="cartDetails">
                          <img
                            className="cartItemImage"
                            src={`${IMAGE_BASE_URL}/${encodeURIComponent(product.Name)}.png`}
                            alt={product.Name}
                          />
                          <div className="itemDetails">
                            <span className="itemUnit" style={{ marginRight: '8px' }}>
                              מחיר ל - {product.Unit === 'bag' ? 'מארז' : isKg ? 'ק"ג' : 'יחידות'}
                            </span>
                            <strong className="itemName" style={{ marginRight: '8px' }}>
                              {product.Name}
                            </strong>
                            <span className="itemPrice" style={{ marginRight: '8px' }}>
                              ₪ {(pricePerUnit * quantity).toFixed(2)}
                            </span>
                            <span className="itemQuantity" style={{ marginRight: '8px' }}>
                              כמות: {quantity}
                            </span>
                          </div>
                          <button
                            className="quantity-btn"
                            onClick={() => handleDecrementQuantity(product.Name)}
                          >
                            ‒
                          </button>
                          <button
                            className="quantity-btn"
                            onClick={() => handleIncrementQuantity(product.Name)}
                          >
                            +
                          </button>
                          <button
                            className="CartDeleteB"
                            onClick={() => handleDeleteCartItem(product.ProductID, product.Name)}
                          >
                            &#10006;
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <button onClick={() => setShowtheCart(false)} className="close-cart-btn">
              ❌
              </button>
            </div>
          )}
        </div>
      </nav>
      <div className="logoDiv">
        <img src={`${IMAGE_BASE_URL}/logo5.webp`} alt="Logo" className="LogoImg" />
      </div>

      <div className="SearchDiv">
        <input
          id="search"
          type="text"
          placeholder="חפש מוצר"
          className="SearchBar"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          aria-label="Search"
        />
        <button className="SearchB" onClick={handleSearch} aria-label="Search button">
          חיפוש
        </button>
      </div>

  

      {/* Search Results */}
      <div className="searchResults">
        {searchResults.length > 0 && (
          <div className="searchResultsHeader">
            <button onClick={handleCloseSearchResults} aria-label="Close search results">
              X
            </button>
          </div>
        )}
        {searchResults.map((product, index) => (
          <VegetableCard
            key={index}
            product={product} // in VegetableCardProps, "vegetable" is typed as Product
            onAddToCart={(p, isKg) => handleAddToCartClick(p, isKg)}
            onInputChange={handleInputChange}
            inputValue={inputValues[product.Name] || '0'}
            isSearchResult={true}
          />
        ))}
      </div>

      {showRegister && (
        <Register onLoginSuccess={() => setShowRegister(false)} onClose={() => setShowRegister(false)} />
      )}

      {showPopup && (
        <div className="popup" role="dialog" aria-labelledby="popupTitle" aria-describedby="popupDesc">
          <h3 id="popupTitle">Check if we are available in your area</h3>
          <label htmlFor="address" id="popupDesc" className="sr-only">
            Enter your address
          </label>
          <input
            id="address"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter your address"
            aria-label="Enter your address"
          />
          <button onClick={handleCheckService} aria-label="Check service availability">
            Check
          </button>
        </div>
      )}

      {/* Accessibility Button/Menu */}
      <button className="accessibilityButton" onClick={toggleAccessibilityMenu} aria-label="Accessibility options">
        נגישות
      </button>
      {showAccessibilityMenu && (
        <div className="accessibilityMenu" role="menu">
          <button onClick={() => adjustTextSize('16px')} aria-label="Small text">
            A-
          </button>
          <button onClick={() => adjustTextSize('18px')} aria-label="Default text">
            A
          </button>
          <button onClick={() => adjustTextSize('20px')} aria-label="Large text">
            A+
          </button>
          <button onClick={() => adjustTextSize('22px')} aria-label="Extra large text">
            A++
          </button>
          <button onClick={toggleHighContrast} aria-label="Toggle high contrast">
            High Contrast
          </button>
        </div>
      )}

      {/* Category Section (example) */}
      <section className="CategoriesSection">
        <div className="categoryCard">
          <Link to="/home" aria-label="Fruits and Vegetables">
            <div
              className="categoryImage"
              style={{ backgroundImage: `url(${IMAGE_BASE_URL}/פירות.webp)` }}
              aria-label="Fruits and Vegetables"
            ></div>
            <span className="categoryLabel">ירקות ופירות</span>
          </Link>
        </div>
        <div className="categoryCard">
          <Link
            to="/home-decor"
            aria-label="Home"
            onClick={() => setProducts(filterProductsByCategory(3))}
          >
            <div
              className="categoryImage"
              style={{ backgroundImage: `url(${IMAGE_BASE_URL}/לבית.webp)` }}
              aria-label="Home"
            ></div>
            <span className="categoryLabel">לבית</span>
          </Link>
        </div>
        <div className="categoryCard">
          <Link
            to="/kitchen"
            aria-label="Kitchen"
            onClick={() => setProducts(filterProductsByCategory(2))}
          >
            <div
              className="categoryImage"
              style={{ backgroundImage: `url(${IMAGE_BASE_URL}/למטבח.webp)` }}
              aria-label="Kitchen"
            ></div>
            <span className="categoryLabel">למטבח</span>
          </Link>
        </div>
      </section>

      {showCartPopup && (
        <div className="cart-popup">
          <div className="cart-popup-content">
            <div className="popup-header">
              <button
                className="continueToCreditCard"
                onClick={() => {
                  handleCloseCartPopup();
                  setShowPaymentForm(true);
                }}
              >
                המשך למילוי פרטי אשראי
              </button>
              <h1 className="summery">סיכום הזמנה</h1>
              <button className="close-popup-btn" onClick={handleCloseCartPopup}>
                ❌
              </button>
            </div>
            <div className="popupContent">
              <div className="orderDetails">
                <ul>
                  {/* Use Object.values to map through cart items */}
                  {Object.values(cartQuantities).map((cartItem, index) => {
                    const { product, quantity, isKg } = cartItem;
                    if (!product || product.isOutOfStock) return null;

                    let pricePerUnit = 0;
                    if (isKg) {
                      pricePerUnit = product.Price;
                    } else if (product.Unit === 'bag') {
                      pricePerUnit = product.Price / product.Quantity;
                    } else {
                      pricePerUnit = product.Price / 2.26;
                    }

                    return (
                      <div className="CartItems" key={product.ProductID}>
                        <div className="cartDetails">
                          <img
                            className="cartItemImages"
                            src={`${IMAGE_BASE_URL}/${encodeURIComponent(product.Name)}.png`}
                            alt={product.Name}
                          />
                          <div className="itemDetails">
                            <span className="itemUnit" style={{ marginRight: '8px' }}>
                              מחיר ל - {product.Unit === 'bag' ? 'מארז' : isKg ? 'ק"ג' : 'יחידות'}
                            </span>
                            <strong className="itemName" style={{ marginRight: '8px' }}>
                              {product.Name}
                            </strong>
                            <span className="itemPrice" style={{ marginRight: '8px' }}>
                              ₪ {(pricePerUnit * quantity).toFixed(2)}
                            </span>
                            <span className="itemQuantity" style={{ marginRight: '8px' }}>
                              כמות: {quantity}
                            </span>
                          </div>
                          <button
                            className="quantity-btn"
                            onClick={() => handleDecrementQuantity(product.Name)}
                          >
                            ‒
                          </button>
                          <button
                            className="quantity-btn"
                            onClick={() => handleIncrementQuantity(product.Name)}
                          >
                            +
                          </button>
                          <button
                            className="CartDeleteB"
                            onClick={() => handleDeleteCartItem(product.ProductID, product.Name)}
                          >
                            &#10006;
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </ul>
              </div>
              <div className="invoiceDetails">
                <div className="logoDivvv">
                  <img src={`${IMAGE_BASE_URL}/logo5.webp`} alt="Logo" className="LogoImgsss" />
                  <h2>חשבונית</h2>
                </div>

                {customerDetails && (
                  <div>
                    <div className="infoItem">
                      <span>{customerDetails.Name}</span>
                      <span> :שם מלא </span>
                    </div>
                    <div className="infoItem">
                      <span>{customerDetails.Email}</span>
                      <span> :כתובת מייל </span>
                    </div>
                    <div className="infoItem">
                      <span>{customerDetails.Phone}</span>
                      <span> :טלפון </span>
                    </div>
                    <p>המשלוח צפוי להגיע ליעד בתוך 3 ימי עסקים</p>
                    <div className="infoItem">
                      <span>
                        {editAddress ? (
                          <div className="editAddressForm">
                            <input
                              type="text"
                              name="Street"
                              value={newAddress.Street}
                              onChange={handleAddressChange}
                              placeholder="רחוב"
                            />
                            <input
                              type="text"
                              name="HouseNumber"
                              value={newAddress.HouseNumber}
                              onChange={handleAddressChange}
                              placeholder="מספר בית"
                            />
                            <input
                              type="text"
                              name="City"
                              value={newAddress.City}
                              onChange={handleAddressChange}
                              placeholder="עיר"
                            />
                            <button onClick={handleSaveAddress}>שמור</button>
                          </div>
                        ) : (
                          <>
                            {newAddress.Street || customerDetails.Street}{' '}
                            {newAddress.HouseNumber || customerDetails.HouseNumber},{' '}
                            {newAddress.City || customerDetails.City}
                            <button onClick={handleEditAddressClick}> ✏️ </button>
                          </>
                        )}
                      </span>
                      <span>:כתובת למשלוח</span>
                    </div>
                    <div className="summary">
                      <div className="infoItem">
                        <span>₪ {calculateTotalPrice()}</span>
                        <span>:עלות כוללת</span>
                      </div>
                      <div className="infoItem">
                        <span>₪ 26</span>
                        <span>:עלות משלוח</span>
                      </div>
                      <div className="infoItem">
                        <span>{(Number(calculateTotalPrice()) + 26).toFixed(2)}</span>
                        <span>:סה"כ סופי</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showPaymentForm && (
        <div className="payment-popup">
          <div className="payment-popup-content">
            <PaymentForm onSubmit={handlePaymentSubmit} onClose={handlePaymentClose} />
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
