import React, { createContext, useState, useContext, ReactNode } from 'react';
import { useAuth } from './AuthProvider';
import Register from './Register';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/** 
 * Replaced `Vegetable` with `Product`
 * and renamed `VegetableID` → `ProductID`
 */
export interface Product {
  ProductID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  AllowsHalfKg: boolean;
  canToggleUnits: boolean;
  isOutOfStock?: boolean;
}

/** 
 * Updated CartItem to include the full product details.
 * This way, when an item is added to the cart, all the product information is saved within the cart item.
 */
interface CartItem {
  product: Product;
  quantity: number;
  isKg: boolean;
}

/** Interface for customer details */
interface CustomerDetails {
  Name: string;
  Email: string;
  Phone: string;
  City: string;
  Street: string;
  HouseNumber: string;
}

/**
 * Updated the cart context:
 * - changed 'vegetables' -> 'products'
 * - changed handleAddToCart(product: Product, isKg: boolean)
 */
interface CartContextType {
  // Now using a dictionary keyed by product name; each CartItem holds its full product details.
  cartQuantities: { [productName: string]: CartItem };
  setCartQuantities: React.Dispatch<
    React.SetStateAction<{ [productName: string]: CartItem }>
  >;
  inputValues: { [productName: string]: string };
  setInputValues: React.Dispatch<React.SetStateAction<{ [productName: string]: string }>>;

  calculateTotalPrice: () => string;

  products: Product[];
  setProducts: React.Dispatch<React.SetStateAction<Product[]>>;

  showRegister: boolean;
  showCartPopup: boolean;
  showPaymentForm: boolean;

  handleAddToCart: (product: Product, isKg: boolean) => Promise<void>;
  handleDeleteCartItem: (productID: number, productName: string) => Promise<void>;

  handleIncrementQuantity: (productName: string) => void;
  handleDecrementQuantity: (productName: string) => void;

  handleContinueToPay: () => void;
  handleCloseRegister: () => void;
  handleCloseCartPopup: () => void;
  handlePaymentClose: () => void;

  fetchCustomerDetails: (userID: number) => Promise<void>;
  customerDetails: CustomerDetails | null;
  setCustomerDetails: React.Dispatch<React.SetStateAction<CustomerDetails | null>>;

  editAddress: boolean;
  setEditAddress: React.Dispatch<React.SetStateAction<boolean>>;
  newAddress: { Street: string; HouseNumber: string; City: string };
  setNewAddress: React.Dispatch<
    React.SetStateAction<{ Street: string; HouseNumber: string; City: string }>
  >;
  handleEditAddress: () => void;
  handleAddressChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSaveAddress: () => void;
}

interface CartProviderProps {
  children: ReactNode;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const { currentUser } = useAuth();

  // Store cart items in a dictionary keyed by product name.
  const [cartQuantities, setCartQuantities] = useState<{ [productName: string]: CartItem }>({});
  const [inputValues, setInputValues] = useState<{ [productName: string]: string }>({});
  
  // renamed from 'vegetables' -> 'products'
  const [products, setProducts] = useState<Product[]>([]);

  const [showRegister, setShowRegister] = useState(false);
  const [showCartPopup, setShowCartPopup] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const [customerDetails, setCustomerDetails] = useState<CustomerDetails | null>(null);

  const [editAddress, setEditAddress] = useState(false);
  const [newAddress, setNewAddress] = useState({ Street: '', HouseNumber: '', City: '' });

  const fetchCustomerDetails = async (userID: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/customers/details/${userID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch customer details');
      }
      const data = await response.json();
      setCustomerDetails(data);
    } catch (error) {
      console.error('Error fetching customer details:', error);
    }
  };

  /**
   * handleAddToCart now expects (product: Product, isKg: boolean)
   * and stores the entire product details in the cart item.
   */
  const handleAddToCart = async (product: Product, isKg: boolean) => {
    const inputValue = inputValues[product.Name];
    const quantity = parseFloat(inputValue);

    if (!isNaN(quantity) && quantity > 0) {
      try {
        const payload = {
          Name: product.Name,
          ProductID: product.ProductID,
          Quantity: quantity,
          ImgUrl: product.ImgUrl,
          Price: product.Price,
          UserID: currentUser?.id ? Number(currentUser.id) : undefined,
          isKg,
        };

        const response = await fetch(`${API_BASE_URL}/cart`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error('Failed to add item to cart');
        }

        // Update cart: store the full product in the cart item.
        setCartQuantities((prev) => ({
          ...prev,
          [product.Name]: {
            product, 
            quantity: (prev[product.Name]?.quantity || 0) + quantity,
            isKg,
          },
        }));

        // Reset input value to "0"
        setInputValues((prev) => ({
          ...prev,
          [product.Name]: '0',
        }));
      } catch (error) {
        console.error('Error adding item to cart:', error);
      }
    }
  };

  /**
   * handleDeleteCartItem: remove the cart item keyed by productName.
   */
  const handleDeleteCartItem = async (productID: number, productName: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/cart/${productID}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          UserID: currentUser?.id ? Number(currentUser.id) : undefined,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete item from cart');
      }

      setCartQuantities((prev) => {
        const updated = { ...prev };
        delete updated[productName];
        return updated;
      });
    } catch (error) {
      console.error('Error deleting item from cart:', error);
    }
  };

  /**
   * Increment/Decrement functions
   */
  const handleIncrementQuantity = (productName: string) => {
    setCartQuantities((prev) => {
      const copy = { ...prev };
      if (copy[productName]?.quantity != null) {
        copy[productName].quantity += 1;
      }
      return copy;
    });
  };

  const handleDecrementQuantity = (productName: string) => {
    setCartQuantities((prev) => {
      const updated = { ...prev };
      if (updated[productName]) {
        updated[productName].quantity = Math.max(0, updated[productName].quantity - 1);
      }
      return updated;
    });
  };

  /**
   * calculateTotalPrice now uses the product details stored in each CartItem.
   */
  const calculateTotalPrice = (): string => {
    const total = Object.keys(cartQuantities).reduce((acc, productName) => {
      const cartItem = cartQuantities[productName];
      if (!cartItem || cartItem.product.isOutOfStock) {
        return acc;
      }

      let itemPrice = 0;
      const { quantity, isKg } = cartItem;
      const { Price, Unit, Quantity } = cartItem.product;

      if (isKg) {
        itemPrice = Price * quantity;
      } else if (Unit === 'bag') {
        // Assume Price is for the whole bag and product.Quantity is the number in a bag
        itemPrice = (Price / Quantity) * quantity;
      } else {
        // Fallback logic (e.g., price divided by a constant)
        itemPrice = (Price / 2.26) * quantity;
      }

      return acc + itemPrice;
    }, 0);

    return total.toFixed(2);
  };

  /**
   * Payment / Cart Popup logic
   */
  const handleContinueToPay = () => {
    if (!currentUser) {
      setShowRegister(true);
    } else {
      setShowCartPopup(true);
    }
  };

  const handleCloseRegister = () => {
    setShowRegister(false);
  };

  const handleCloseCartPopup = () => {
    setShowCartPopup(false);
    setShowPaymentForm(true);
  };

  const handlePaymentClose = () => {
    setShowPaymentForm(false);
  };

  /**
   * Address Editing
   */
  const handleEditAddress = () => {
    setEditAddress(true);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
  };

  const handleSaveAddress = () => {
    setEditAddress(false);
  };

  return (
    <CartContext.Provider
      value={{
        cartQuantities,
        setCartQuantities,
        inputValues,
        setInputValues,

        products,
        setProducts,

        handleAddToCart,
        handleDeleteCartItem,

        handleIncrementQuantity,
        handleDecrementQuantity,

        calculateTotalPrice,

        showRegister,
        showCartPopup,
        showPaymentForm,
        handleContinueToPay,
        handleCloseRegister,
        handleCloseCartPopup,
        handlePaymentClose,

        fetchCustomerDetails,
        customerDetails,
        setCustomerDetails,

        editAddress,
        setEditAddress,
        newAddress,
        setNewAddress,
        handleEditAddress,
        handleAddressChange,
        handleSaveAddress,
      }}
    >
      {children}

      {showRegister && (
        <Register
          onLoginSuccess={() => setShowRegister(false)}
          onClose={() => setShowRegister(false)}
        />
      )}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
