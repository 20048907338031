import React, { useState, useEffect } from 'react';
import './VegetableManager.css';

/** Interface for products (formerly "Vegetable") */
interface Product {
  ProductID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  AllowsHalfKg: boolean;
  canToggleUnits: boolean;
  isOutOfStock: boolean;
  CategoryID: number;
  Description?: string; // <-- NEW optional field
}

/** Interface for categories */
interface Category {
  CategoryID: number;
  Name: string;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const VegetableManager: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [products, setProducts] = useState<Product[]>([]);

  // ADDED: Include a "Description" in formData
  const [formData, setFormData] = useState({
    Name: '',
    Price: '',
    Quantity: '',
    ImgUrl: '',
    Unit: 'kg', // default
    CategoryID: '', // default empty
    canToggleUnits: false,
    Description: '', // <-- NEW
  });

  const [selectedCategoryID, setSelectedCategoryID] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []);

  // Set a default CategoryID once categories are loaded (optional)
  useEffect(() => {
    if (categories.length > 0 && formData.CategoryID === '') {
      setFormData((prev) => ({ ...prev, CategoryID: String(categories[0].CategoryID) }));
    }
  }, [categories, formData.CategoryID]);

  /** Fetch all products from /api/products */
  const fetchProducts = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/products`);
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      const adjustedData: Product[] = data.map((prod: any) => ({
        ProductID: prod.ProductID,
        Name: prod.Name,
        Price: prod.Price,
        Quantity: prod.Quantity,
        ImgUrl: prod.ImgUrl,
        Unit: prod.Unit,
        AllowsHalfKg: prod.AllowsHalfKg,
        canToggleUnits: prod.canToggleUnits || prod.CanToggleUnits,
        isOutOfStock: prod.isOutOfStock || prod.IsOutOfStock,
        CategoryID: prod.CategoryID,
        Description: prod.Description || '',
      }));
      console.log('Fetched products:', adjustedData);
      setProducts(adjustedData);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  /** Fetch categories from /api/products/categories */
  const fetchCategories = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/products/categories`);
      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }
      const data = await response.json();
      console.log('Fetched categories:', data);
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  /** Handle input form changes */
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    console.log(`Field changed: ${name} -> ${newValue}`);
    setFormData((prev) => ({ ...prev, [name]: newValue }));
  };

  /** Quick check that required fields are filled */
  const validateForm = () => {
    const { Name, Price, Quantity, CategoryID, ImgUrl } = formData;
    return !!(Name && Price && Quantity && CategoryID && ImgUrl);
  };

  /** Handle submission to add a product */
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      setErrorMessage('All fields (except description) are required');
      return;
    }
    setErrorMessage(null);

    const convertedCategoryID = Number(formData.CategoryID);
    console.log(
      'Submitting product with CategoryID:',
      formData.CategoryID,
      'converted to',
      convertedCategoryID
    );

    try {
      const response = await fetch(`${API_BASE_URL}/products`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          Name: formData.Name,
          Price: parseFloat(formData.Price),
          Quantity: parseInt(formData.Quantity),
          CategoryID: convertedCategoryID,
          ImgUrl: formData.ImgUrl,
          Unit: formData.Unit,
          canToggleUnits: formData.canToggleUnits,
          Description: formData.Description, // <-- SEND DESCRIPTION
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add product');
      }

      // Reset form values
      setFormData({
        Name: '',
        Price: '',
        Quantity: '',
        CategoryID: '',
        ImgUrl: '',
        Unit: 'kg',
        canToggleUnits: false,
        Description: '',
      });
      setSuccessMessage('Product added successfully');

      // Refresh list
      fetchProducts();
    } catch (error) {
      console.error('Error adding product:', error);
      setErrorMessage('Error adding product');
    }
  };

  /** Delete a product by ID */
  const handleDelete = async (productId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/products/${productId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete product');
      }
      fetchProducts();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  /** Toggle product in/out of stock */
  const handleToggleStock = async (productId: number, currentStockStatus: boolean) => {
    try {
      const response = await fetch(`${API_BASE_URL}/products/${productId}/toggle-stock`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          isOutOfStock: !currentStockStatus,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update stock status');
      }
      fetchProducts();
    } catch (error) {
      console.error('Error updating stock status:', error);
    }
  };

  /** Filter products by selected category */
  const filteredProducts = selectedCategoryID
    ? products.filter((p) => p.CategoryID === selectedCategoryID)
    : products;

  return (
    <div className="vegetable-manager">
      <h2>Product Manager</h2>

      {/* Add New Product Form */}
      <form onSubmit={handleSubmit} className="vegetable-form">
        <div className="form-row">
          <input
            type="text"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Name"
          />
          <input
            type="number"
            step="0.01"
            name="Price"
            value={formData.Price}
            onChange={handleChange}
            placeholder="Price"
          />
          <input
            type="text"
            name="Quantity"
            value={formData.Quantity}
            onChange={handleChange}
            placeholder="Quantity"
          />
          <select name="CategoryID" value={formData.CategoryID} onChange={handleChange}>
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category.CategoryID} value={category.CategoryID}>
                {category.Name}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="ImgUrl"
            value={formData.ImgUrl}
            onChange={handleChange}
            placeholder="Image URL"
          />
          <select name="Unit" value={formData.Unit} onChange={handleChange}>
            <option value="kg">לקילו</option>
            <option value="bag">לשקית</option>
            <option value="item">ליחידה</option>
          </select>
          <label className="toggle-units">
            Allow Toggle Units:
            <input
              type="checkbox"
              name="canToggleUnits"
              checked={!!formData.canToggleUnits}
              onChange={handleChange}
            />
          </label>
        </div>

        {/* ADD A TEXTAREA FOR DESCRIPTION */}
        <div className="form-row">
          <textarea
            name="Description"
            value={formData.Description}
            onChange={handleChange}
            placeholder="Enter product description (optional)"
            rows={3}
          />
        </div>

        <button type="submit">Add Product</button>
      </form>

      {/* Error / Success Messages */}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}

      {/* Category Filter */}
      <div>
        <h3>Filter by Category</h3>
        <select onChange={(e) => setSelectedCategoryID(Number(e.target.value) || null)}>
          <option value="">All Categories</option>
          {categories.map((category) => (
            <option key={category.CategoryID} value={category.CategoryID}>
              {category.Name}
            </option>
          ))}
        </select>
      </div>

      {/* Product List */}
      <ul className="vegetable-list">
        {filteredProducts.map((product: Product) => (
          <li
            key={product.ProductID}
            className={`vegetable-item ${product.isOutOfStock ? 'out-of-stock' : ''}`}
          >
            {product.Name} - {product.Price.toFixed(2)} - {product.Quantity} - {product.Unit}
            <button onClick={() => handleToggleStock(product.ProductID, product.isOutOfStock)}>
              {product.isOutOfStock ? 'Mark as In Stock' : 'Mark as Out of Stock'}
            </button>
            <button onClick={() => handleDelete(product.ProductID)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VegetableManager;
