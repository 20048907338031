import React, { useState, useEffect } from 'react';
import './Home.css';
import VegetableCard from './VegetableCard'; // You can later rename this to "ProductCard"
import { useAuth } from './AuthProvider';
import { useCart } from './CartProvider';
import { toast } from 'react-toastify';  // <-- Import Toastify here

interface Product {
  ProductID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  AllowsHalfKg: boolean;
  canToggleUnits: boolean;
  isOutOfStock: boolean;
  CategoryID: number; // Added to help with filtering
}

interface HomeProps {
  filterCategory?: number; // Optional prop for filtering products by category
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

const Home: React.FC<HomeProps> = ({ filterCategory }) => {
  const { currentUser } = useAuth();
  const {
    cartQuantities,
    setCartQuantities,
    inputValues,
    setInputValues,
    handleAddToCart,
  } = useCart();

  const [products, setProducts] = useState<Product[]>([]);
  const [addError, setAddError] = useState<string | null>(null);

  // Fetch products on mount
  useEffect(() => {
    fetchProducts();
  }, [currentUser]);

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/products`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      // Normalize properties if needed.
      const productsWithProperties: Product[] = data.map((prod: any) => ({
        ...prod,
        canToggleUnits: prod.canToggleUnits || prod.CanToggleUnits || false,
        isOutOfStock: prod.isOutOfStock || prod.IsOutOfStock || false,
      }));

      setProducts(productsWithProperties);

      // Initialize inputValues for each product
      const initialInputValues: { [name: string]: string } = {};
      productsWithProperties.forEach((p: Product) => {
        initialInputValues[p.Name] = '0';
      });
      setInputValues(initialInputValues);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  /**
   * Handle change for quantity input fields for a given product.
   */
  const handleInputChange = (productName: string, newValue: string) => {
    const newQuantity = parseFloat(newValue);
    if (!isNaN(newQuantity)) {
      const updatedValue = Math.max(0, newQuantity).toString();
      setInputValues((prev) => ({
        ...prev,
        [productName]: updatedValue,
      }));
    }
  };

  /**
   * Delete item from the cart (if it's out-of-stock, etc.)
   */
  const handleDeleteCartItem = async (productID: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/cart/${productID}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          UserID: currentUser?.id ? Number(currentUser.id) : undefined,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to delete item from cart');
      }
      // Remove the deleted product from cartQuantities
      setCartQuantities((prev) => {
        const updated = { ...prev };
        const productToDelete = products.find((p) => p.ProductID === productID);
        if (productToDelete) {
          delete updated[productToDelete.Name];
        }
        return updated;
      });
    } catch (error) {
      console.error('Error deleting item from cart:', error);
      setAddError('Failed to delete item from cart. Please try again.');
    }
  };

  /**
   * Remove out-of-stock items from the cart when products update.
   */
  useEffect(() => {
    const outOfStockItems = Object.keys(cartQuantities).filter((name) => {
      const product = products.find((p) => p.Name === name);
      return product && product.isOutOfStock;
    });

    if (outOfStockItems.length > 0) {
      setAddError(
        `The following items are now out of stock and have been removed from your cart: ${outOfStockItems.join(
          ', '
        )}`
      );
      outOfStockItems.forEach((name) => {
        const product = products.find((p) => p.Name === name);
        if (product) {
          handleDeleteCartItem(product.ProductID);
        }
      });
    }
  }, [products, cartQuantities]);

  // If a filter category is provided, create a filtered list of products.
  const filteredProducts = filterCategory
    ? products.filter((p) => p.CategoryID === filterCategory)
    : products;

  return (
    <div className="bigDiv">
      {filteredProducts.map((product, index) => {
        console.log(`Home.tsx - ${product.Name}: isOutOfStock = ${product.isOutOfStock}`);
        return (
          <VegetableCard
            key={index}
            product={{
              ...product,
              ImgUrl: `${IMAGE_BASE_URL}/${encodeURIComponent(product.Name)}.png`,
            }}
            onAddToCart={(p, isKg, priceInKg) => {
              if (p.isOutOfStock) {
                setAddError(`${p.Name} is currently out of stock.`);
                return;
              }
              // Use the cart context function to actually add the product
              handleAddToCart(p, isKg);

              // Show a "toast" notification on successful addition
              toast.success(`"${p.Name}" נוספה לעגלה בהצלחה!`, {
                position: 'top-right',
                autoClose: 3000,
              });
            }}
            onInputChange={handleInputChange}
            inputValue={inputValues[product.Name] || '0'}
          />
        );
      })}

      {addError && <p className="error">{addError}</p>}
    </div>
  );
};

export default Home;
