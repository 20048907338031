import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Home from './Home';
import Register from './Register';
import Header from './Header';
import Footer from './Footer';
import VegetableManager from './VegetableManager';
import Welcome from './Welcome';
import Login from './Login';
import PaymentForm from './PaymentForm';
import { AuthProvider } from './AuthProvider';
import { CartProvider } from './CartProvider';

// 1) Import ToastContainer and Toastify CSS
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  const handlePaymentSubmit = (formData: any) => {
    console.log('Payment form submitted:', formData);
  };

  const handlePaymentClose = () => {
    console.log('Payment form closed');
  };

  return (
    <GoogleOAuthProvider clientId="851165767509-30sql5l6vo3nkg3d025skn2833b9f92b.apps.googleusercontent.com">
      <Router>
        <AuthProvider>
          <CartProvider>
            <div className="app-container">
              <Header />

              {/** 2) Place ToastContainer once in your app (e.g., after Header) */}
              <ToastContainer />

              <div className="main-content">
                <Routes>
                  {/* Update the routes to pass filterCategory props */}
                  <Route path="/" element={<Home filterCategory={1} />} />
                  <Route path="/home" element={<Home filterCategory={1} />} />
                  <Route path="/home-decor" element={<Home filterCategory={3} />} />
                  <Route path="/kitchen" element={<Home filterCategory={2} />} />
                  <Route
                    path="/register"
                    element={<Register onLoginSuccess={() => {}} onClose={() => {}} />}
                  />
                  <Route path="/vegetablemanager" element={<VegetableManager />} />
                  <Route path="/welcome" element={<Welcome />} />
                  <Route
                    path="/login"
                    element={<Login onClose={() => {}} onRegister={() => {}} />}
                  />
                  <Route
                    path="/payment"
                    element={
                      <PaymentForm onSubmit={handlePaymentSubmit} onClose={handlePaymentClose} />
                    }
                  />
                </Routes>
              </div>
              <Footer />
            </div>
          </CartProvider>
        </AuthProvider>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
