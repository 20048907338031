
import React, { useState } from 'react';
import './PaymentForm.css';

const PaymentForm: React.FC<{ onSubmit: (formData: any) => void, onClose: () => void }> = ({ onSubmit, onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [idCardholder, setIdCardholder] = useState('');
  const [cardholderName, setCardholderName] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = {
      phoneNumber,
      cardNumber,
      expiryDate,
      cvv,
      idCardholder,
      cardholderName
    };
    onSubmit(formData);
  };

  return (
    <div className='PaymentForm'>
      <h2 className='h2ppp'>פרטי תשלום</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor='phoneNumber'>מספר טלפון של בעל הכרטיס</label>
          <input
            type='tel'
            id='phoneNumber'
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor='cardNumber'>מספר כרטיס אשראי</label>
          <input
            type='text'
            id='cardNumber'
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor='expiryDate'>תוקף</label>
          <input
            type='text'
            id='expiryDate'
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            placeholder='MM/YY'
            pattern='^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$'
            required
          />
        </div>
        <div>
          <label htmlFor='cvv'>3 ספרות בגב הכרטיס</label>
          <input
            type='text'
            id='cvv'
            value={cvv}
            onChange={(e) => setCvv(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor='idCardholder'>תעודת זהות של בעל הכרטיס</label>
          <input
            type='text'
            id='idCardholder'
            value={idCardholder}
            onChange={(e) => setIdCardholder(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor='cardholderName'>שם בעל הכרטיס</label>
          <input
            type='text'
            id='cardholderName'
            value={cardholderName}
            onChange={(e) => setCardholderName(e.target.value)}
            required
          />
        </div>
        <button className='payB' type='submit'>שלח פרטי תשלום</button>
        <button className='payB' type='button' onClick={onClose}>ביטול</button>
      </form>
    </div>
  );
};

export default PaymentForm;

